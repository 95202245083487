
.footer {
}

@media (min-width: 800px) {
    .footer {
        position: fixed;
        padding: 10px 10px 0px 10px;
        bottom: 0;
        width: 100%;
        /* Height of the footer*/
        height: 100px;
        background: whitesmoke;
    }
}