.content {
   display: flex;
   align-items: center;
    width: 100%;
    flex-direction: column;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.button-gap {
    margin: 50px;
    width: 250px;
}

.lets-write {
    font-size: 100px;
    font-family: "Brush Script MT";
}

@media (max-width: 1054px) {
    .flex-container {
        flex-direction: column;
    }
    .button-gap{
        margin:20px;
    }
    .lets-write {
        font-size: 40px;
    }
}
